import React, { useState } from 'react';
import { AuthTextField, AuthButton, AuthLink, AuthTitle, AuthLinkButton } from './StyledAuthComponents';
import { Box } from '@mui/material';
import { supabase } from '../../utils/supabaseClient';
import { useDispatch } from 'react-redux';
import { setError, clearError } from '../../store/authSlice';
import { AppDispatch } from '../../store/store';
import SuccessNotification from './SuccessNotification';
import ErrorNotification from './ErrorNotification';
import validator from 'validator';
import OAuthButtons from './OAuthButtons';

interface LoginFormProps {
  onSwitchToRegister: () => void;
  onSwitchToRecover: () => void;
}

const LoginForm: React.FC<LoginFormProps> = ({ onSwitchToRegister, onSwitchToRecover }) => {
  const dispatch = useDispatch<AppDispatch>();
  
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);

  // Form validation errors
  const [validationErrors, setValidationErrors] = useState<{ email?: string; password?: string }>({});

  const validateForm = (): boolean => {
    const errors: { email?: string; password?: string } = {};

    const emailValid = validator.isEmail(sanitizeEmail(email), {allow_underscores: true});

    if (!email) {
      errors.email = 'Email is required.';
    } else if (!emailValid) {
      errors.email = 'Please enter a valid email address.';
    }

    if (!password) {
      errors.password = 'Password is required.';
    }

    setValidationErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const sanitizeEmail = (email: string) => {
    return email.toLowerCase().trim();
  };

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setErrorMsg('');
    setSuccessMsg('');
    dispatch(clearError());
    setValidationErrors({});
    setLoading(true);
    try{
        if (!validateForm()) {
          setLoading(false);
        return;
      }

      const { data, error } = await supabase.auth.signInWithPassword({
        email: sanitizeEmail(email),
        password,
      });

      if (error) {
        dispatch(setError(error.message));
        setErrorMsg(error.message);
      } else if (data.session) {
        setSuccessMsg('Login successful! Redirecting...');
      }
    } catch (error) {
      console.error('Error during login:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box component="form" onSubmit={handleLogin}>
      <AuthTitle>Login</AuthTitle>
      {errorMsg && <ErrorNotification message={errorMsg} />}
      {successMsg && <SuccessNotification message={successMsg} />}
      <OAuthButtons />
      <AuthTextField
        label="Email"
        variant="outlined"
        type="email"
        required
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        error={!!validationErrors.email}
        helperText={validationErrors.email}
      />
      <AuthTextField
        label="Password"
        variant="outlined"
        type="password"
        required
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        error={!!validationErrors.password}
        helperText={validationErrors.password}
      />
      <AuthButton type="submit" variant="contained" color="primary" disabled={loading}>
        {loading ? 'Logging in...' : 'Login'}
      </AuthButton>
      <AuthLink>
        <AuthLinkButton onClick={onSwitchToRecover}>
          Forgot Password?
        </AuthLinkButton>
      </AuthLink>
      <AuthLink>
        Don't have an account? <AuthLinkButton onClick={onSwitchToRegister}>
          Register
        </AuthLinkButton>
      </AuthLink>
    </Box>
  );
};

export default LoginForm;