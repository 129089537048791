enum LogLevel {
    DEBUG,
    INFO,
    WARN,
    ERROR,
  }
  
  class Logger {
    private static instance: Logger;
    private logLevel: LogLevel = LogLevel.INFO;
  
    private constructor() {}
  
    static getInstance(): Logger {
      if (!Logger.instance) {
        Logger.instance = new Logger();
      }
      return Logger.instance;
    }
  
    setLogLevel(level: LogLevel) {
      this.logLevel = level;
    }
  
    private log(level: LogLevel, message: string, ...args: any[]) {
      if (level >= this.logLevel) {
        //const timestamp = new Date().toISOString();
        //const prefix = `[${timestamp}] [${LogLevel[level]}]`;
        //console.log(prefix, message, ...args);
      }
    }
  
    debug(message: string, ...args: any[]) {
      this.log(LogLevel.DEBUG, message, ...args);
    }
  
    info(message: string, ...args: any[]) {
      this.log(LogLevel.INFO, message, ...args);
    }
  
    warn(message: string, ...args: any[]) {
      this.log(LogLevel.WARN, message, ...args);
    }
  
    error(message: string, ...args: any[]) {
      this.log(LogLevel.ERROR, message, ...args);
    }
  }
  
  export const logger = Logger.getInstance();