import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setModels } from '../store/modelSlice';
import type { ModelConfig } from '../api/models';

export const useModelConfigUpdate = () => {
  const dispatch = useDispatch();

  const handleModelConfigUpdate = useCallback((data: ModelConfig[] ) => {
    console.log('useModelConfigUpdate: Received model config update');
    const serializedData = data.map((config: ModelConfig) => ({
      ...config,
      lastUpdated: (config.lastUpdated ? new Date(config.lastUpdated).toISOString() : undefined) || '',
    }));
    dispatch(setModels(serializedData));
  }, [dispatch]);

  return handleModelConfigUpdate;
};