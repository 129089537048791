import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';

interface AppWrapperProps {
  darkMode: boolean;
  toggleDarkMode: () => void;
}

function AppWrapper({ darkMode, toggleDarkMode }: AppWrapperProps) {
  return (
    <Router>
      <App darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
    </Router>
  );
}

export default AppWrapper;