import React, { useState, useEffect } from 'react';
import { AuthTextField, AuthButton, AuthLink, AuthTitle, AuthLinkButton } from './StyledAuthComponents';
import { Box } from '@mui/material';
import { supabase } from '../../utils/supabaseClient';
import { useDispatch } from 'react-redux';
import { setError, clearError } from '../../store/authSlice';
import { AppDispatch } from '../../store/store';
import { useNavigate } from 'react-router-dom';
import SuccessNotification from './SuccessNotification';
import ErrorNotification from './ErrorNotification';

interface ResetPasswordFormProps {
  onSwitchToLogin: () => void;
}

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({ onSwitchToLogin }) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  // Form validation errors
  const [validationErrors, setValidationErrors] = useState<{ password?: string; confirmPassword?: string }>({});

  useEffect(() => {
    // Check if user is authenticated
    const checkSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        setErrorMsg('You must be authenticated to reset your password.');
        setTimeout(() => {
          navigate('/login');
        }, 2000);
      }
    };
    
    checkSession();
  }, [navigate]);

  const validateForm = (): boolean => {
    const errors: { password?: string; confirmPassword?: string } = {};

    if (!password) {
      errors.password = 'Password is required.';
    } else {
      // Password strength: min 6 chars, at least 1 letter and 1 digit
      const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/;
      if (!passwordRegex.test(password)) {
        errors.password = 'Password must be at least 6 characters long and include at least one letter and one number.';
      }
    }

    if (!confirmPassword) {
      errors.confirmPassword = 'Please confirm your password.';
    } else if (password !== confirmPassword) {
      errors.confirmPassword = 'Passwords do not match.';
    }

    setValidationErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleResetPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(clearError());
    setValidationErrors({});

    if (!validateForm()) {
      return;
    }

    const { error } = await supabase.auth.updateUser({ 
      password: password 
    });

    if (error) {
      dispatch(setError(error.message));
      setErrorMsg(error.message);
    } else {
      setSuccessMsg('Password has been reset successfully! Redirecting to home page...');
      setTimeout(() => {
        navigate('/');
      }, 2000);
    }
  };

  return (
    <Box component="form" onSubmit={handleResetPassword}>
      <AuthTitle>Reset Password</AuthTitle>
      {errorMsg && <ErrorNotification message={errorMsg} />}
      {successMsg && <SuccessNotification message={successMsg} />}
      <AuthTextField
        label="New Password"
        variant="outlined"
        type="password"
        required
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        error={!!validationErrors.password}
        helperText={validationErrors.password}
      />
      <AuthTextField
        label="Confirm New Password"
        variant="outlined"
        type="password"
        required
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        error={!!validationErrors.confirmPassword}
        helperText={validationErrors.confirmPassword}
      />
      <AuthButton type="submit" variant="contained" color="primary">
        Reset Password
      </AuthButton>
      <AuthLink>
        <AuthLinkButton onClick={onSwitchToLogin}>
          Back to Login
        </AuthLinkButton>
      </AuthLink>
    </Box>
  );
};

export default ResetPasswordForm;