import { createSlice, PayloadAction, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import { RootState } from './store';
import { getStore } from '../utils/storeInjector';
import { supabase } from '../utils/supabaseClient';
import { User } from '../types';
import { Session as supabaseSession } from '@supabase/supabase-js';
import { axiosInstance } from '../api/auth';
import { setIsFirstLogin } from './uiSlice';
import { fetchNotifications } from './notificationSlice';

// const API_URL = CONFIG.API_URL;

// const axiosInstance = axios.create({
//   baseURL: API_URL,
//   withCredentials: true
// });


export const loginDirect = createAsyncThunk(
  'auth/login',
  async (session: supabaseSession, { rejectWithValue }) => {
    try {
      //console.log('AuthSlice: loginDirect', session);
      const response = await axiosInstance.post('/auth/login', { session: session });
      if(response.status !== 200) {
        console.log('AuthSlice: loginDirect error', response.data.error);
        return rejectWithValue(response.data.error);
      }
      //console.log('AuthSlice: loginDirect response', response.data);
      await getStore().dispatch(setIsFirstLogin(response.data.user.isFirstLogin));
      await getStore().dispatch(setCredentials({ user: response.data.user, sessionId: response.data.sessionId, session: session }));
      await getStore().dispatch(fetchNotifications());
      return response.data;
    } catch (error: any) {
      //logout();
      return rejectWithValue(error.message || 'An unexpected error occurred during login.');
    }
  }
);


export const logoutDirect = createAsyncThunk(
  'auth/logout',
  async (_, { rejectWithValue }) => {
    try {
      const { error } = await supabase.auth.signOut();

      if (error) {
        return rejectWithValue(error?.message);
      }
      return true;
    } catch (error: any) {
      console.error('Error during logout process:', error);
      return rejectWithValue('Logout failed. Please try again.');
    }
  }
);

export const getSupabaseUser = async () => {
  try {
    const { data, error } = await supabase.auth.getUser();
    if (error) {
      return null;
    }
    return data.user;
  } catch (error: any) {
    return null;
  }
};

interface AuthState {
  isAuthenticated: boolean;
  user: User | null;
  accessToken: string | null;
  isLoadingAuth: boolean;
  error: string | null;
  tokenExpiry: number | null;
  sessionId: string | null;
  session: supabaseSession | null;
  resetPassword: boolean;
}

const initialState: AuthState = {
  isAuthenticated: false,
  user: null,
  accessToken: null,
  isLoadingAuth: false,
  error: null,
  tokenExpiry: null,
  sessionId: null,
  session: null,
  resetPassword: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action: PayloadAction<{ user?: User; sessionId?: string; session: supabaseSession }>) => {
      if (action.payload.user) {
        state.user = action.payload.user;
      }
      state.session = action.payload.session;
      state.accessToken = action.payload.session.access_token;
      state.error = null;
      if (action.payload.session.expires_at) {
        if(Date.now()/1000 < action.payload.session.expires_at){
          state.tokenExpiry = action.payload.session.expires_at;
          state.isAuthenticated = true;
        } else {
          state.tokenExpiry = null;
          state.isAuthenticated = false;
        }
      }
      if (action.payload.sessionId) {
        state.sessionId = action.payload.sessionId;
      }
    },
    setSession: (state, action: PayloadAction<supabaseSession>) => {
      state.session = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoadingAuth = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    updateAccessToken: (state, action: PayloadAction<{ accessToken: string | null; expiresAt: number | null }>) => {
      state.accessToken = action.payload.accessToken;
      if (action.payload.expiresAt) {
        state.tokenExpiry = action.payload.expiresAt;
      }
      if(state.tokenExpiry && Date.now()/1000 < state.tokenExpiry){
        state.isAuthenticated = true;
      } else {
        state.isAuthenticated = false;
      }
    },
    clearError: (state) => {
      state.error = null;
    },
    clearAuthState: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.accessToken = null;
      state.isLoadingAuth = false;
      state.error = null;
      state.tokenExpiry = null;
      state.sessionId = null;
      state.resetPassword = false;
    },
    updateUser: (state, action: PayloadAction<User>) => {
      if (action.payload) {
        state.user = action.payload;
      }
    },
    setResetPassword: (state, action: PayloadAction<boolean>) => {
      state.resetPassword = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Handle loginDirect
    builder.addCase(loginDirect.pending, (state) => {
      state.isLoadingAuth = true;
      state.error = null;
    });
    builder.addCase(loginDirect.fulfilled, (state, action) => {
      state.isLoadingAuth = false;
      state.isAuthenticated = true;
    });
    builder.addCase(loginDirect.rejected, (state, action) => {
      state.isLoadingAuth = false;
      state.isAuthenticated = false;
      state.user = null;
      state.accessToken = null;
      state.tokenExpiry = null;
    });
    // Handle logout
    builder.addCase(logoutDirect.pending, (state) => {
      state.isLoadingAuth = true;
      state.error = null;
    });
    builder.addCase(logoutDirect.fulfilled, (state) => {
      state.isLoadingAuth = false;
    });
    builder.addCase(logoutDirect.rejected, (state, action) => {
      state.isLoadingAuth = false;
      state.error = action.payload as string;
    });
  },
});

export const selectAuthState = (state: RootState) => state.auth;

export const selectIsAuthenticated = createSelector(
  selectAuthState,
  (auth) => auth.isAuthenticated
);

export const selectUser = createSelector(
  selectAuthState,
  (auth) => auth.user
);

export const selectAuthError = createSelector(
  selectAuthState,
  (auth) => auth.error
);

export const selectIsLoadingAuth = createSelector(
  selectAuthState,
  (auth) => auth.isLoadingAuth
);

export const selectAccessToken = createSelector(
  selectAuthState,
  (auth) => auth.accessToken
);

export const { 
  setCredentials,
  setSession,
  setLoading, 
  setError,
  updateAccessToken, 
  clearError,
  clearAuthState,
  updateUser
} = authSlice.actions;

export default authSlice.reducer;