import axios, { AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios';
import { logoutDirect, clearAuthState } from '../store/authSlice';
import { disconnectSocket } from '../services/socketService';
import { CONFIG } from '../config';
import { clearChatState } from '../store/chatSlice';
import { clearModelState } from '../store/modelSlice';
import { clearUIState } from '../store/uiSlice';
import { getStore } from '../utils/storeInjector';
import { User as SupabaseUser } from '@supabase/supabase-js';
const API_URL = CONFIG.API_URL;

interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
  _retry?: boolean;
  _retryCount?: number;
  skipAuth?: boolean;
}

export const axiosInstance = axios.create({
  baseURL: API_URL,
  withCredentials: true
});

// Axios interceptor for requests to attach authorization token
axiosInstance.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const customConfig = config as CustomAxiosRequestConfig;
    const token = getStore().getState().auth.accessToken;
    if (!customConfig.skipAuth && token) {
      config.headers.set('Authorization', `Bearer ${token}`);
    }
    return config;
  },
  (error) => Promise.reject(error)
);

const skipAuthConfig: AxiosRequestConfig = { headers: { skipAuth: true } };

export const registrationCheck = async (data: { email: string }) => {
  const response = await axiosInstance.post('/auth/registration-check', data, skipAuthConfig);
  return response.data;
};

export const setIsFirstLogin = async (data: { isFirstLogin: boolean }) => {
  const response = await axiosInstance.post('/auth/set-is-first-login', data);
  return response.data;
};

export const initiateRegistration = async (data: {user: SupabaseUser}) => {
  try{
    const response = await axiosInstance.post('/auth/registration-start', data, skipAuthConfig);
    return response.status === 200;
  } catch(error){
    console.error('Error initiating registration:', error);
    return false;
  }
}

export const logout = async ({cleanupOnly = false}) => {
  // Dispatch logout action
  if(!cleanupOnly){
    
    const response = await getStore().dispatch(logoutDirect()).unwrap();
    if (response !== true) {
      console.log('AuthApi: cleanup - logout error:', response);
      throw new Error('Supabase logout failed. Please try again.');
    }
    return response;
  } else {
    // console.log('AuthApi: cleanup - logging out');
    // if(getStore().getState().auth.sessionId){
    //   try {
    //     const response = await axiosInstance.post('/auth/logout', { sessionId: getStore().getState().auth.sessionId });
    //     if (response.status !== 200) {
    //       console.log('AuthApi: cleanup - logout error:', response.data);
    //     }
    //   } catch (error) {
    //     console.error('Error during logout:', error);
    //   }
    // }
  }

  disconnectSocket();
  console.log('AuthApi: cleanup - redirecting to login');
  //window.location.href = '/login';

  // Clear all relevant Redux states
  await getStore().dispatch(clearAuthState());
  await getStore().dispatch(clearChatState());
  await getStore().dispatch(clearModelState());
  await getStore().dispatch(clearUIState());

  // Clear any local storage items
  localStorage.removeItem('lastActiveChat');
  localStorage.removeItem('lastOpenedChatId');
  localStorage.removeItem('lastModelConfigUpdateTime');
  localStorage.removeItem('userSettings');
  localStorage.removeItem('oauth_provider_token');
  localStorage.removeItem('oauth_provider_refresh_token');

  // Clear any session storage items
  sessionStorage.clear();

  // Reset any global variables
};

export const logoutAllSessions = async () => {
  const response = await axiosInstance.post('/auth/logout-all');
  return response.data;
};

export const checkSessionStatus = async () => {
  try {
    const response = await axiosInstance.get('/auth/check-session');
    return response.data.isActive;
  } catch (error) {
    console.error('Error checking session status:', error);
    return false;
  }
};

export const refreshTokenOnServer = async () => {
  try {
    const response = await axiosInstance.post('/auth/refresh-token', {
      session: getStore().getState().auth.session,
      sessionId: getStore().getState().auth.sessionId
    });
    return response.data;
  } catch (error) {
    console.error('Error refreshing token:', error);
    throw new Error('Failed to refresh token on server.');
  }
};