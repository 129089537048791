import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Chat as ChatOriginal, Message as MessageOriginal } from '../types';
import { RootState } from './store';

// Define enhanced types
interface Message extends MessageOriginal {}

interface Chat extends ChatOriginal {
  messages: Message[];
}

// Define the shape of the chat state
interface ChatState {
  chats: Chat[];
  selectedChatId: string | null;
}

const initialState: ChatState = {
  chats: [],
  selectedChatId: null,
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    /**
     * Sets the entire list of chats.
     * Normalizes chats and initializes their message states.
     */
    setChats: (state, action: PayloadAction<ChatOriginal[]>) => {
      //console.log('setChats payload:', action.payload);
      state.chats = action.payload.map((chat) => ({
        ...chat,
        isStreaming: false,
        createdAt: chat.createdAt || new Date().toISOString(),
        updatedAt: chat.updatedAt || new Date().toISOString(),
        lastMessageTime: chat.lastMessageTime || undefined,
        messages: chat.messages || [],
      }));
      //console.log('setChats state.chats:', state.chats);
    },

    /**
     * Sets the selected chat by its ID.
     */
    setSelectedChatId: (state, action: PayloadAction<string | null>) => {
      console.log('setSelectedChatId called');
      state.selectedChatId = action.payload;
    },

    /**
     * Adds a new chat to the state.
     */
    addChat: (state, action: PayloadAction<ChatOriginal>) => {
      const newChat: Chat = {
        ...action.payload,
        isStreaming: false,
        createdAt: action.payload.createdAt || new Date().toISOString(),
        updatedAt: action.payload.updatedAt || new Date().toISOString(),
        lastMessageTime: action.payload.lastMessageTime || '',
        messages: action.payload.messages || [],
      };
      state.chats.unshift(newChat);
    },

    /**
     * Updates an existing chat.
     * Retains the `isStreaming` status and updates timestamps.
     */
    updateChat: (state, action: PayloadAction<Omit<ChatOriginal, 'messages'>>) => {
      const index = state.chats.findIndex(chat => chat._id === action.payload._id);
      if (index !== -1) {
        const existingChat = state.chats[index];
        state.chats[index] = {
          ...existingChat,
          ...action.payload,
          //isStreaming: existingChat.isStreaming || false,
          //createdAt: action.payload.createdAt ? new Date(action.payload.createdAt).toISOString() : existingChat.createdAt,
          updatedAt: action.payload.updatedAt ? new Date(action.payload.updatedAt).toISOString() : new Date().toISOString(),
          lastMessageTime: action.payload.lastMessageTime || existingChat.lastMessageTime,
          //messages: existingChat.messages,
        };
      } else {
        console.error(`Chat with ID ${action.payload._id} not found.`);
      }
    },

    /**
     * Deletes a chat by its ID.
     */
    deleteChat: (state, action: PayloadAction<string>) => {
      const chatExists = state.chats.some(chat => chat._id === action.payload);
      if (chatExists) {
        state.chats = state.chats.filter(chat => chat._id !== action.payload);
        if (state.selectedChatId === action.payload) {
          state.selectedChatId = null;
        }
      } else {
        console.error(`Attempted to delete non-existent chat with ID ${action.payload}.`);
      }
    },

    /**
     * Clears the entire chat state to its initial state.
     */
    clearChatState: () => initialState,

    /**
     * Clears all messages from a specific chat.
     */
    clearMessagesInChat: (state, action: PayloadAction<string>) => {
      const chat = state.chats.find(chat => chat._id === action.payload);
      if (chat) {
        chat.messages = [];
        chat.lastMessageTime = undefined;
        chat.lastMessage = undefined;
        chat.updatedAt = new Date().toISOString();
      } else {
        console.error(`Chat with ID ${action.payload} not found when clearing messages.`);
      }
    },

    /**
     * Sets all messages for a specific chat.
     */
    setMessagesInChat: (
      state,
      action: PayloadAction<{ chatId: string; messages: Message[] }>
    ) => {
      const { chatId, messages } = action.payload;
      const chat = state.chats.find(chat => chat._id === chatId);
      if (chat) {
        chat.messages = messages;
        const lastMessage = messages[messages.length - 1];
        if (lastMessage) {
          chat.lastMessageTime = lastMessage.createdAt;
          chat.lastMessage = lastMessage;
        }
        chat.updatedAt = new Date().toISOString();
      } else {
        console.error(`Chat with ID ${chatId} not found when setting all messages.`);
      }
    },

    /**
     * Adds a message to a specific chat.
     */
    addMessageToChat: (
      state,
      action: PayloadAction<{ chatId: string; message: Message }>
    ) => {
      const { chatId, message } = action.payload;
      const chatIndex = state.chats.findIndex(chat => chat._id === chatId);
      if (chatIndex !== -1) {
        const chat = state.chats[chatIndex];
        const existingMessage = chat.messages.find(msg => msg._id === message._id);
        if (!existingMessage) {
          chat.messages.push(message);
          chat.lastMessageTime = message.createdAt;
          chat.lastMessage = message;
          chat.updatedAt = new Date().toISOString();
          
          // Move the updated chat to the top of the chats array
          state.chats.splice(chatIndex, 1);
          state.chats.unshift(chat);
        } else {
          console.warn(`addMessageToChat: Message with ID ${message._id} already exists in chat ${chatId}.`);
        }
      } else {
        console.error(`addMessageToChat: Chat with ID ${chatId} not found when adding a message.`);
      }
    },

    /**
     * Updates a message within a specific chat.
     */
    updateMessageInChat: (
      state,
      action: PayloadAction<{ chatId: string; message: Message }>
    ) => {
      const { chatId, message } = action.payload;
      console.log('updateMessageInChat payload:', action.payload);
      const chat = state.chats.find(chat => chat._id === chatId);
      if (chat) {
        const messageIndex = chat.messages.findIndex(msg => msg._id === message._id);
        if (messageIndex !== -1) {
          chat.messages[messageIndex] = { ...chat.messages[messageIndex], ...message };

          // Update lastMessage if this is the last message
          if (chat.messages.length - 1 === messageIndex) {
            chat.lastMessageTime = message.createdAt;
            chat.lastMessage = message;
          }

          chat.updatedAt = new Date().toISOString();
        } else {
          console.error(`updateMessageInChat: Message with ID ${message._id} not found in chat ${chatId}.`);
        }
      } else {
        console.error(`updateMessageInChat: Chat with ID ${chatId} not found when updating a message.`);
      }
    },

    /**
     * Updates the _id of a message for a specific chat.
     */
    updateMessageId: (
      state,
      action: PayloadAction<{ chatId: string; message: Message; newMessageId: string }>
    ) => {
      const { chatId, message, newMessageId } = action.payload;
      const chat = state.chats.find(chat => chat._id === chatId);
      if (chat) {
        const messageToUpdate = chat.messages.find(msg => msg._id === message._id);
        if (messageToUpdate) {
          messageToUpdate._id = newMessageId;
        }
      }
    },

    /**
     * Initiates a streaming message within a specific chat.
     */
    initiateStreamingMessage: (
      state,
      action: PayloadAction<{ chatId: string; message: Message }>
    ) => {
      const { chatId, message } = action.payload;
      const chat = state.chats.find(chat => chat._id === chatId);
      if (chat) {
        chat.isStreaming = true;

        const existingMessage = chat.messages.find(msg => msg._id === message._id);
        if (!existingMessage) {
          chat.messages.push({
            ...message,
            content: '',
            isComplete: false,
          });
          chat.lastMessageTime = message.createdAt;
          chat.lastMessage = message;
          chat.updatedAt = new Date().toISOString();
        } else {
          console.warn(`initiateStreamingMessage: Message with ID ${message._id} already exists in chat ${chatId}.`);
        }
      } else {
        console.error(`initiateStreamingMessage: Chat with ID ${chatId} not found when initiating a streaming message.`);
      }
    },

    /**
     * Updates the content of a streaming message within a specific chat.
     */
    updateStreamingMessage: (
      state,
      action: PayloadAction<{ chatId: string; content: string }>
    ) => {
      const { chatId, content } = action.payload;
      const chat = state.chats.find(chat => chat._id === chatId);
      if (chat && chat.messages.length > 0) {
        const lastMessage = chat.messages[chat.messages.length - 1];
        lastMessage.content = content;
      } else {
        console.error(`updateStreamingMessage: Chat with ID ${chatId} not found or has no messages when updating streaming message.`);
      }
    },

    /**
     * Finalizes a streaming message by setting its final content and marking it as complete.
     */
    finalizeStreamingMessage: (
      state,
      action: PayloadAction<{ chatId: string }>
    ) => {
      const { chatId } = action.payload;
      const chat = state.chats.find(chat => chat._id === chatId);
      if (chat && chat.messages.length > 0) {
        const lastMessage = chat.messages[chat.messages.length - 1];
        lastMessage.isComplete = true;

        // Update lastMessage
        chat.lastMessage = lastMessage;
        chat.lastMessageTime = lastMessage.createdAt;

        chat.isStreaming = false;
      } else {
        console.error(`finalizeStreamingMessage: Chat with ID ${chatId} not found or has no messages when finalizing streaming message.`);
      }
    },

    abortStreamingMessage: (
      state,
      action: PayloadAction<{ chatId: string }>
    ) => {
      const { chatId } = action.payload;
      const chat = state.chats.find(chat => chat._id === chatId);
      if (chat && chat.messages.length > 0) {
        chat.messages.pop();
        chat.isStreaming = false;
        chat.updatedAt = new Date().toISOString();
        chat.lastMessage = chat.messages[chat.messages.length - 1];
        chat.lastMessageTime = chat.messages[chat.messages.length - 1].updatedAt;
      }
    },

    updateMessageAsFailed: (
      state,
      action: PayloadAction<{ chatId: string; messageId: string }>
    ) => {
      const { chatId, messageId } = action.payload;
      const chat = state.chats.find(chat => chat._id === chatId);
      if (chat) {
        const message = chat.messages.find(msg => msg._id === messageId);
        if (message) {
          message.isFailed = true;
        }
      }
    }
  },
});

// Export actions
export const {
  setChats,
  setSelectedChatId,
  addChat,
  updateChat,
  deleteChat,
  clearChatState,
  addMessageToChat,
  updateMessageInChat,
  initiateStreamingMessage,
  updateStreamingMessage,
  finalizeStreamingMessage,
  clearMessagesInChat,
  setMessagesInChat,
  updateMessageId,
  updateMessageAsFailed,
  abortStreamingMessage,
} = chatSlice.actions;

// Export selectors for chats and messages
export const selectAllChats = (state: { chat: ChatState }) => state.chat.chats;

export const selectChatById = (state: { chat: ChatState }, id: string) =>
  state.chat.chats.find(chat => chat._id === id);

export const selectChatIds = (state: { chat: ChatState }) =>
  state.chat.chats.map(chat => chat._id);

export const selectSelectedChat = (state: { chat: ChatState }) =>
  state.chat.selectedChatId
    ? state.chat.chats.find(chat => chat._id === state.chat.selectedChatId) || null
    : null;

// Selector for chat messages
export const selectChatMessages = (state: RootState, chatId: string | null) => 
  chatId ? state.chat.chats.find(chat => chat._id === chatId)?.messages || [] : [];

export default chatSlice.reducer;
