import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { setIsFirstLogin as setIsFirstLoginApi } from '../api/auth';
import { RootState } from './store';

// Type definition for active streams, mapping chat IDs to their streaming status
type ActiveStreams = Record<string, boolean>;

/**
 * Interface representing the UI state
 */
interface UIState {
  uiLoading: boolean;
  chatLoading: boolean;
  activeStreams: ActiveStreams;
  currentContextUsage: number;
  maxContextLimit: number;
  darkMode: boolean;
  isFirstLogin: boolean;
  settingsOpen: boolean;
  selectedSetting: string;
  streamPreference: boolean;
  isLeftSidebarOpen: boolean;
  isRightSidebarOpen: boolean;
}

const initialState: UIState = {
  uiLoading: false,
  chatLoading: false,
  activeStreams: {},
  currentContextUsage: 0,
  maxContextLimit: 4096,
  darkMode: false,
  isFirstLogin: false,
  settingsOpen: false,
  selectedSetting: 'Profile',
  streamPreference: true,
  isLeftSidebarOpen: true,
  isRightSidebarOpen: false,
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    /**
     * Sets the global loading state of the application
     */
    setUiLoading: (state, action: PayloadAction<boolean>) => {
      state.uiLoading = action.payload;
    },
    setChatLoading: (state, action: PayloadAction<boolean>) => {
      state.chatLoading = action.payload;
    },
    setIsLeftSidebarOpen: (state, action: PayloadAction<boolean>) => {
      state.isLeftSidebarOpen = action.payload;
    },
    setIsRightSidebarOpen: (state, action: PayloadAction<boolean>) => {
      state.isRightSidebarOpen = action.payload;
    },
    /**
     * Updates the streaming status for a specific chat
     */
    setActiveStream: (
      state,
      action: PayloadAction<{ chatId: string; isStreaming: boolean }>
    ) => {
      const { chatId, isStreaming } = action.payload;
      if (typeof isStreaming !== 'boolean') {
        console.error(`Invalid isStreaming value for chat ${chatId}: ${isStreaming}`);
        return;
      }
      state.activeStreams[chatId] = isStreaming;
    },
    setSettingsOpen: (state, action: PayloadAction<boolean>) => {
      state.settingsOpen = action.payload;
    },
    setSelectedSetting: (state, action: PayloadAction<string>) => {
      state.selectedSetting = action.payload;
    },
    /**
     * Updates the current context usage
     */
    setCurrentContextUsage: (state, action: PayloadAction<number>) => {
      if (action.payload < 0) {
        console.error(`Invalid context usage value: ${action.payload}`);
        return;
      }
      state.currentContextUsage = action.payload;
    },
    /**
     * Sets the maximum context limit
     */
    setMaxContextLimit: (state, action: PayloadAction<number>) => {
      if (action.payload <= 0) {
        console.error(`Invalid max context limit: ${action.payload}`);
        return;
      }
      state.maxContextLimit = action.payload;
    },
    /**
     * Resets the UI state to its initial values
     */
    clearUIState: () => initialState,
    /**
     * Toggles the dark mode
     */
    toggleDarkMode: (state) => {
      state.darkMode = !state.darkMode;
    },
    setIsFirstLogin: (state, action: PayloadAction<boolean>) => {
      state.isFirstLogin = action.payload;
    },
    setStreamPreference: (state, action: PayloadAction<boolean>) => {
      state.streamPreference = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateIsFirstLogin.pending, (state, action) => {
      state.uiLoading = true;
    });
    builder.addCase(updateIsFirstLogin.fulfilled, (state, action) => {
      state.uiLoading = false;
    });
    builder.addCase(updateIsFirstLogin.rejected, (state, action) => {
      state.uiLoading = false;
    });
  },
});

export const {
  setUiLoading,
  setActiveStream,
  setSettingsOpen,
  setSelectedSetting,
  setCurrentContextUsage,
  setMaxContextLimit,
  clearUIState,
  toggleDarkMode,
  setIsFirstLogin,
  setStreamPreference,
  setChatLoading,
  setIsLeftSidebarOpen,
  setIsRightSidebarOpen,
} = uiSlice.actions;

export const updateIsFirstLogin = createAsyncThunk<void, boolean, { state: RootState }>(
  'ui/updateIsFirstLogin',
  async (isFirstLogin: boolean, { dispatch, getState }) => {
    const user = getState().auth.user;
    if (user) {
      await setIsFirstLoginApi({ isFirstLogin });
      dispatch(setIsFirstLogin(isFirstLogin));
    } else {
      console.error('updateIsFirstLogin: User not found');
    }
  }
);

export default uiSlice.reducer;
