import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModelConfig } from '../types';

interface ModelState {
  models: ModelConfig[];
  selectedModel: string;
}

const initialState: ModelState = {
  models: [],
  selectedModel: '',
};

const modelSlice = createSlice({
  name: 'model',
  initialState,
  reducers: {
    setModels: (state, action: PayloadAction<ModelConfig[]>) => {
      state.models = action.payload;
    },
    setSelectedModel: (state, action: PayloadAction<string | ((prevModel: string) => string)>) => {
      state.selectedModel = typeof action.payload === 'function'
        ? action.payload(state.selectedModel)
        : action.payload;
    },
    clearModelState: (state) => {
      return initialState;
    },
  },
});

export const { setModels, setSelectedModel, clearModelState } = modelSlice.actions;
export default modelSlice.reducer;