import React from 'react';
import { Alert } from '@mui/material';

interface SuccessNotificationProps {
  message: string;
}

const SuccessNotification: React.FC<SuccessNotificationProps> = ({ message }) => {
  return (
    <Alert severity="success" sx={{ marginBottom: '16px' }}>
      {message}
    </Alert>
  );
};

export default SuccessNotification;