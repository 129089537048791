import {Suspense, lazy, useEffect} from 'react';
import { Route, Routes } from 'react-router-dom';
import AuthPage from './components/auth/AuthPage';
import ProtectedRoute from './components/ProtectedRoute';
import { SocketProvider } from './contexts/SocketProvider';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from 'react-redux';
import { RootState } from './store/store';
import { getSupabaseUser, clearAuthState, updateAccessToken } from './store/authSlice';
import { getStore } from './utils/storeInjector';
import { supabase } from './utils/supabaseClient';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MainChatScreen = lazy(() => import('./components/chat/MainChatScreen'));

interface AppProps {
  darkMode: boolean;
  toggleDarkMode: () => void;
}

function App({ darkMode, toggleDarkMode }: AppProps) {

  const { isAuthenticated } = useSelector((state: RootState) => state.auth);

  // Check if the user is authenticated on loading the app
  useEffect(() => {
    const checkUser = async () => {
      const user = await getSupabaseUser();
      if(user){
        const { data, error } = await supabase.auth.getSession();
        if(data.session && !error){
          getStore().dispatch(updateAccessToken({
            accessToken: data.session.access_token,
            expiresAt: data.session.expires_at!,
          }));
        }
      } else {
        console.log('App: User not authenticated, clearing auth state');
        getStore().dispatch(clearAuthState());
      }
    };
    checkUser();

    return () => {
      console.log('App: Unmounting, clearing auth state');
      getStore().dispatch(clearAuthState());
    };
  }, []);


  return (
    <>
    <SocketProvider>
      <Suspense fallback={<CircularProgress />}>
        <Routes>
          <Route path="/login" element={<AuthPage />} />
          <Route path="/register" element={<AuthPage />} />
          <Route path="/forgot-password" element={<AuthPage />} />
          <Route path="/reset-password" element={<AuthPage />} />
          <Route path="/" element={
            isAuthenticated ?
            <ProtectedRoute>
              <MainChatScreen darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
            </ProtectedRoute>
            :
            <AuthPage />
          } />
        </Routes>
      </Suspense>
    </SocketProvider>
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme={darkMode ? 'dark' : 'light'}
    />
    </>
  );
}

export default App;