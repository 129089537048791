import React, { useState, useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import AppWrapper from './AppWrapper';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import CssBaseline from '@mui/material/CssBaseline';
import { lightTheme, darkTheme } from './theme';
import { createTheme } from '@mui/material/styles';
import { Provider } from 'react-redux';
import { store } from './store/store';
import ErrorBoundary from './components/ErrorBoundary';

const container = document.getElementById('root');
if (container) {
  const root = ReactDOM.createRoot(container);

  const Main = () => {
    const [darkMode, setDarkMode] = useState(() => {
      const savedTheme = localStorage.getItem('theme');
      return savedTheme ? savedTheme === 'dark' : false;
    });

    const theme = useMemo(() => createTheme(darkMode ? darkTheme : lightTheme), [darkMode]);

    const toggleDarkMode = () => {
      setDarkMode((prevMode) => {
        const newMode = !prevMode;
        localStorage.setItem('theme', newMode ? 'dark' : 'light');
        return newMode;
      });
    };

    useEffect(() => {
      const savedTheme = localStorage.getItem('theme');
      if (!savedTheme) {
        localStorage.setItem('theme', 'light');
      } else {
        setDarkMode(savedTheme === 'dark');
      }
    }, []);

    return (
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <StyledThemeProvider theme={theme}>
            <CssBaseline />
            <AppWrapper darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
          </StyledThemeProvider>
        </MuiThemeProvider>
      </Provider>
    );
  };

  root.render(
     <React.StrictMode>
      <ErrorBoundary>
        <Main />
      </ErrorBoundary>
     </React.StrictMode>
  );
} else {
  console.error('Root container not found');
}