import React, { useState } from 'react';
import { AuthTextField, AuthButton, AuthLink, AuthTitle, AuthLinkButton } from './StyledAuthComponents';
import { Box, CircularProgress } from '@mui/material';
import { supabase } from '../../utils/supabaseClient';
import { useDispatch } from 'react-redux';
import { setError, clearError } from '../../store/authSlice';
import { AppDispatch } from '../../store/store';
import ErrorNotification from './ErrorNotification';

interface PasswordRecoveryFormProps {
  onSwitchToLogin: () => void;
}

const PasswordRecoveryForm: React.FC<PasswordRecoveryFormProps> = ({ onSwitchToLogin }) => {
  const dispatch = useDispatch<AppDispatch>();

  const [email, setEmail] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  // Form validation errors
  const [validationErrors, setValidationErrors] = useState<{ email?: string }>({});

  const [loading, setLoading] = useState(false);

  const validateForm = (): boolean => {
    const errors: { email?: string } = {};

    // Simple email regex for validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      errors.email = 'Email is required.';
    } else if (!emailRegex.test(email)) {
      errors.email = 'Please enter a valid email address.';
    }

    setValidationErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handlePasswordRecovery = async (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(clearError());
    setValidationErrors({});
    setLoading(true);

    if (!validateForm()) {
      setLoading(false);
      return;
    }

    const { error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: `${window.location.origin}/reset-password`,
    });

    if (error) {
      dispatch(setError(error.message));
      setErrorMsg(error.message);
    } else {
      setSuccessMsg('Password recovery email sent! Please check your email for further instructions. You can close this page.');
    }
    
    setLoading(false);
  };

  return (
    <>
      {successMsg ? (
        <Box sx={{ textAlign: 'center', p: 2 }}>
          <AuthTitle>Recovery Email Sent</AuthTitle>
          <Box sx={{ mt: 2, mb: 2 }}>
            Please check your email for further instructions. Please close this page.
          </Box>
        </Box>
      ) : (
        <Box sx={{ position: 'relative' }}>
          {loading && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                zIndex: 1,
              }}
            >
              <CircularProgress />
            </Box>
          )}
          <Box 
            component="form" 
            onSubmit={handlePasswordRecovery}
            sx={{
              opacity: loading ? 0.6 : 1,
              pointerEvents: loading ? 'none' : 'auto',
            }}
          >
            <AuthTitle>Recover Password</AuthTitle>
            {errorMsg && <ErrorNotification message={errorMsg} />}
            <AuthTextField
              label="Email"
              variant="outlined"
              type="email"
              required
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setErrorMsg('');
                setValidationErrors({});
              }}
              error={!!validationErrors.email}
              helperText={validationErrors.email}
            />
            <AuthButton 
              type="submit" 
              variant="contained" 
              color="primary"
              disabled={loading}
            >
              Send Recovery Email
            </AuthButton>
            <AuthLink>
              <AuthLinkButton onClick={onSwitchToLogin}>
                Back to Login
              </AuthLinkButton>
            </AuthLink>
          </Box>
        </Box>
      )}
    </>
  );
};

export default PasswordRecoveryForm;