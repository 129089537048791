import React, { useState } from 'react'
import { Box, Button, Divider, Typography, CircularProgress } from '@mui/material'
import { styled } from '@mui/material/styles'
import { OAUTH_PROVIDERS } from './OAuthProviders'
import { supabase } from '../../utils/supabaseClient'
import { useDispatch } from 'react-redux'
import { setError, clearError } from '../../store/authSlice'
import { AppDispatch } from '../../store/store'

const OAuthButtonsContainer = styled(Box)({
  display: 'flex',
  gap: '12px',
  justifyContent: 'center',
  marginBottom: '24px'
})

const OAuthButton = styled(Button)(({ theme }) => ({
  minWidth: 'unset',
  width: '48px',
  height: '48px',
  padding: '12px',
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& svg': {
    fontSize: '24px'
  }
}))

const OrDivider = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  margin: '24px 0',
  '& hr': {
    flexGrow: 1,
    borderColor: theme.palette.divider
  },
  '& span': {
    margin: '0 16px',
    color: theme.palette.text.secondary,
    fontSize: '14px'
  }
}))

const OAuthButtons: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>()
  const [loadingProvider, setLoadingProvider] = useState<string | null>(null)

  const handleOAuthLogin = async (provider: string) => {
    try {
      dispatch(clearError())
      setLoadingProvider(provider)
      
      const { error } = await supabase.auth.signInWithOAuth({
        provider: provider as any,
        options: {
          redirectTo: window.location.origin
        }
      })
      
      if (error) throw error
    } catch (error: any) {
      console.error('OAuth error:', error)
      dispatch(setError(error.message || 'Failed to authenticate with provider'))
    } finally {
      setLoadingProvider(null)
    }
  }

  return (
    <>
      <OAuthButtonsContainer>
        {OAUTH_PROVIDERS.map((provider) => {
          const Icon = provider.icon
          const isLoading = loadingProvider === provider.id
          
          return (
            <OAuthButton
              key={provider.id}
              variant="contained"
              onClick={() => handleOAuthLogin(provider.id)}
              disabled={!!loadingProvider}
              sx={{
                bgcolor: provider.color,
                '&:hover': {
                  bgcolor: provider.hoverColor
                }
              }}
              aria-label={`Continue with ${provider.name}`}
            >
              {isLoading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <Icon />
              )}
            </OAuthButton>
          )
        })}
      </OAuthButtonsContainer>
      <OrDivider>
        <Divider />
        <Typography component="span">or</Typography>
        <Divider />
      </OrDivider>
    </>
  )
}

export default OAuthButtons