import React, { useState } from 'react';
import { AuthTextField, AuthButton, AuthLink, AuthTitle, AuthLinkButton } from './StyledAuthComponents';
import { Box, CircularProgress } from '@mui/material';
import { supabase } from '../../utils/supabaseClient';
import { useDispatch } from 'react-redux';
import { setError, clearError } from '../../store/authSlice';
import { AppDispatch } from '../../store/store';
import ErrorNotification from './ErrorNotification';
import { initiateRegistration, registrationCheck } from '../../api/auth';
import validator from 'validator';
import OAuthButtons from './OAuthButtons';


interface RegistrationFormProps {
  onSwitchToLogin: () => void;
}

const RegistrationForm: React.FC<RegistrationFormProps> = ({ onSwitchToLogin }) => {
  const dispatch = useDispatch<AppDispatch>();
  
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [validationErrors, setValidationErrors] = useState<{ email?: string; password?: string; confirmPassword?: string }>({});
  const [loading, setLoading] = useState(false); // New loading state

  const sanitizeEmail = (email: string) => {
    return email.toLowerCase().trim();
  };

  const validateForm = (): boolean => {
    const errors: { email?: string; password?: string; confirmPassword?: string } = {};



    const emailValid = validator.isEmail(sanitizeEmail(email), {allow_underscores: true});

    if (!email) {
      errors.email = 'Email is required.';
    } else if (!emailValid) {
      errors.email = 'Please enter a valid email address.';
    }

    if (!password) {
      errors.password = 'Password is required.';
    } else {
      // Password strength: min 6 chars, at least 1 letter and 1 digit
      const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/;
      if (!passwordRegex.test(password)) {
        errors.password = 'Password must be at least 6 characters long and include at least one letter and one number.';
      }
    }

    if (!confirmPassword) {
      errors.confirmPassword = 'Please confirm your password.';
    } else if (password !== confirmPassword) {
      errors.confirmPassword = 'Passwords do not match.';
    }

    setValidationErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleRegister = async (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(clearError());
    setErrorMsg('');
    setSuccessMsg('');
    setValidationErrors({});
    setLoading(true); // Set loading to true when registration starts

    if (!validateForm()) {
      setLoading(false); // Reset loading if validation fails
      return;
    }

    const { exists } = await registrationCheck({ email: sanitizeEmail(email) });

    if (exists) {
      setErrorMsg('Account already exists! Please use the login page.');
      setLoading(false); // Reset loading if account exists
      return;
    }

    const { data, error } = await supabase.auth.signUp({
      email: sanitizeEmail(email),
      password,
    });

    if (error) {
      dispatch(setError(error.message));
      setErrorMsg(error.message);
      setLoading(false); // Reset loading on error
      return;
    }

    if (data.user) {
      const registrationResponse = await initiateRegistration({ user: data.user });
      if (registrationResponse) {
        setSuccessMsg('Registration successful! Please check your email for verification. You can close this page.');
      } else {
        setErrorMsg('An error occurred during registration. Please try again.');
      }
    } else {
      setErrorMsg('An error occurred during registration. Please try again.');
    }
    

    setLoading(false); // Reset loading when process is complete
  };

  return (
    <>
      {successMsg ? (
        <Box sx={{ textAlign: 'center', p: 2 }}>
          <AuthTitle>Registration Successful</AuthTitle>
          <Box sx={{ mt: 2, mb: 2 }}>
            Please verify your email to login. You can close this page.
          </Box>
        </Box>
      ) : (
        <Box sx={{ position: 'relative' }}>
          {loading && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                zIndex: 1,
              }}
            >
              <CircularProgress />
            </Box>
          )}
          <Box 
            component="form" 
            onSubmit={handleRegister}
            sx={{
              opacity: loading ? 0.6 : 1,
              pointerEvents: loading ? 'none' : 'auto',
            }}
          >
            <AuthTitle>Register</AuthTitle>
            {errorMsg && <ErrorNotification message={errorMsg} />}
            <OAuthButtons />
            <AuthTextField
              label="Email"
              variant="outlined"
              type="email"
              required
              value={email}
              onChange={(e) => {setEmail(e.target.value); setErrorMsg(''); setSuccessMsg(''); setValidationErrors({});}}
              error={!!validationErrors.email}
              helperText={validationErrors.email}
            />
            <AuthTextField
              label="Password"
              variant="outlined"
              type="password"
              required
              value={password}
              onChange={(e) => {setPassword(e.target.value); setErrorMsg(''); setSuccessMsg(''); setValidationErrors({});}}
              error={!!validationErrors.password}
              helperText={validationErrors.password}
            />
            <AuthTextField
              label="Confirm Password"
              variant="outlined"
              type="password"
              required
              value={confirmPassword}
              onChange={(e) => {setConfirmPassword(e.target.value); setErrorMsg(''); setSuccessMsg(''); setValidationErrors({});}}
              error={!!validationErrors.confirmPassword}
              helperText={validationErrors.confirmPassword}
            />
            <AuthButton 
              type="submit" 
              variant="contained" 
              color="primary" 
              disabled={loading}
            >
              Register
            </AuthButton>
            <AuthLink>
              <AuthLinkButton onClick={onSwitchToLogin}>
                Already have an account? Login
              </AuthLinkButton>
            </AuthLink>
          </Box>
        </Box>
      )}
    </>
  );
};

export default RegistrationForm;
