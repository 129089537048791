import React from 'react';
import { Alert } from '@mui/material';

interface ErrorNotificationProps {
  message: string;
}

const ErrorNotification: React.FC<ErrorNotificationProps> = ({ message }) => {
  return (
    <Alert severity="error" sx={{ marginBottom: '16px' }}>
      {message}
    </Alert>
  );
};

export default ErrorNotification;