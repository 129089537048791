import { Box, Typography, TextField, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const AuthPageContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    backgroundColor: 'transparent',
    overflow: 'hidden',
    position: 'relative',
  }));
  
export const AuthCard = styled(Box)(({ theme }) => ({
    background: theme.palette.background.default,
    //background: `linear-gradient(135deg, ${theme.palette.background.paper} 0%, ${theme.palette.background.default} 100%)`,
    borderRadius: '12px',
    padding: '32px',
    width: '100%',
    maxWidth: '400px',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.3s ease-in-out',
    zIndex: 2, // Increased z-index
    position: 'relative', // Added position
    '&:hover': {
      boxShadow: '0 6px 25px rgba(0, 0, 0, 0.15)',
    },
  }));

export const AuthTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.h4.fontSize,
  fontWeight: theme.typography.h4.fontWeight,
  color: theme.palette.text.primary,
  marginBottom: '24px',
  textAlign: 'center',
}));

export const AuthTextField = styled(TextField)(({ theme }) => ({
  marginBottom: '16px',
  width: '100%',
  '& .MuiOutlinedInput-root': {
    zIndex: 1,
    borderRadius: '8px',
    backgroundColor: theme.palette.background.default,
    transition: 'all 0.3s ease-in-out',
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
      borderWidth: '2px',
    },
  },
  '& .MuiFormLabel-root': {
    backgroundColor: theme.palette.background.default,
    zIndex: 5,
    padding: '0 4px',
    //borderRadius: '8px',
    //transform: 'translate(14px, -9px) scale(0.75)',
  }
}));

export const AuthLinkButton = styled('button')({
  background: 'none',
  border: 'none',
  color: 'inherit',
  textDecoration: 'underline',
  cursor: 'pointer',
  padding: 0,
});

export const AuthButton = styled(Button)(({ theme }) => ({
  width: '100%',
  marginTop: '16px',
  borderRadius: '8px',
  textTransform: 'none',
  fontWeight: 600,
  padding: '12px 0',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

export const AuthLink = styled(Typography)(({ theme }) => ({
  marginTop: '16px',
  textAlign: 'center',
  color: theme.palette.text.secondary,
  '& a': {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    fontWeight: 600,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));