import { Notification } from '../types';
import { axiosInstance } from './auth';


export const fetchNotifications = async (): Promise<Notification[]> => {
  const response = await axiosInstance.get(`/notifications`);
  return response.data;
};

export const markNotificationAsRead = async (id: string): Promise<Notification> => {
  const response = await axiosInstance.put(`/notifications/${id}/read`);
  return response.data;
};

export const markAllNotificationsAsRead = async (): Promise<void> => {
  await axiosInstance.put(`/notifications/read-all`);
};

export const deleteNotification = async (id: string): Promise<void> => {
  await axiosInstance.delete(`/notifications/${id}`);
};