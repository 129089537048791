import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { Socket } from 'socket.io-client';
import { disconnectSocket, SocketEventCallbacks, getSocket } from '../services/socketService';
import { useModelConfigUpdate } from '../hooks/useModelConfigUpdate';
import { logger } from '../utils/logger';
import { getStore } from '../utils/storeInjector';
import { updateAccessToken } from '../store/authSlice';
import { supabase } from '../utils/supabaseClient';
import { addNotification } from '../store/notificationSlice';
import { useAppDispatch } from '../hooks/reduxHooks';

interface SocketState {
  socket: Socket | null;
  isConnected: boolean;
  isReconnecting: boolean;
  reconnectionAttempts: number;
}

type SocketAction =
  | { type: 'SET_SOCKET'; payload: Socket | null }
  | { type: 'SET_CONNECTED'; payload: boolean }
  | { type: 'SET_RECONNECTING'; payload: boolean }
  | { type: 'SET_RECONNECTION_ATTEMPTS'; payload: number };

const initialState: SocketState = {
  socket: null,
  isConnected: false,
  isReconnecting: false,
  reconnectionAttempts: 0,
};

function socketReducer(state: SocketState, action: SocketAction): SocketState {
  switch (action.type) {
    case 'SET_SOCKET':
      return { ...state, socket: action.payload };
    case 'SET_CONNECTED':
      return { ...state, isConnected: action.payload };
    case 'SET_RECONNECTING':
      return { ...state, isReconnecting: action.payload };
    case 'SET_RECONNECTION_ATTEMPTS':
      return { ...state, reconnectionAttempts: action.payload };
    default:
      return state;
  }
}

const SocketContext = createContext<SocketState>(initialState);

export const useSocket = () => useContext(SocketContext);

export const SocketProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, socketDispatch] = useReducer(socketReducer, initialState);
  const reduxDispatch = useAppDispatch();
  const handleModelConfigUpdate = useModelConfigUpdate();

  const getAccessToken = async () => {
    const {data, error} = await supabase.auth.getSession();
    if(error) {
      //console.log('SocketProvider: Error getting access token:', error);
      return;
    } else if(data && data.session) {
      getStore().dispatch(updateAccessToken({accessToken: data.session.access_token, expiresAt: data.session.expires_at || null}));
      return true;
    }
    return false;
  };
  
  useEffect(() => {
    getAccessToken();
  }, []);

  
  const { accessToken, isAuthenticated } = getStore().getState().auth;
  

  useEffect(() => {
    //console.log('SocketProvider: Initializing...');

    if (!isAuthenticated || !accessToken) {
      //console.log('SocketProvider: User not authenticated, skipping socket initialization');
      return;
    }

    const socketCallbacks: SocketEventCallbacks = {
      onConnect: () => {
        socketDispatch({ type: 'SET_CONNECTED', payload: true });
        socketDispatch({ type: 'SET_RECONNECTING', payload: false });
        socketDispatch({ type: 'SET_RECONNECTION_ATTEMPTS', payload: 0 });
        logger.info('SocketProvider: Connected to socket');
      },
      onDisconnect: (reason) => {
        socketDispatch({ type: 'SET_CONNECTED', payload: false });
        logger.info(`SocketProvider: Disconnected from socket. Reason: ${reason}`);
      },
      onReconnectAttempt: (attempt) => {
        socketDispatch({ type: 'SET_RECONNECTION_ATTEMPTS', payload: attempt });
        socketDispatch({ type: 'SET_RECONNECTING', payload: true });
        logger.info(`SocketProvider: Reconnection attempt #${attempt}`);
      },
      onReconnectFailed: () => {
        socketDispatch({ type: 'SET_RECONNECTING', payload: false });
        logger.error('SocketProvider: Reconnection failed');
      },
      onReconnect: (attempt) => {
        socketDispatch({ type: 'SET_CONNECTED', payload: true });
        socketDispatch({ type: 'SET_RECONNECTING', payload: false });
        socketDispatch({ type: 'SET_RECONNECTION_ATTEMPTS', payload: attempt });
        logger.info(`SocketProvider: Successfully reconnected on attempt #${attempt}`);
      },
      onError: (error) => {
        logger.error('SocketProvider: Socket error:', error);
      },
      onModelConfigUpdate: handleModelConfigUpdate,
      onNewNotification: (notification) => {
        console.log(`SocketProvider: Received new notification: ${notification.title}`);
        reduxDispatch(addNotification(notification));
        // toast.info(notification.title, {
        //   position: "top-right",
        //   autoClose: 3000,
        // });
      },
    };

    if (isAuthenticated && accessToken) {
      //console.log('SocketProvider: Authenticated, initializing socket');
      const socketInstance = getSocket(accessToken, socketCallbacks);
      if (socketInstance) {
        socketDispatch({ type: 'SET_SOCKET', payload: socketInstance });
      }
    } else {
      console.log('SocketProvider: Not authenticated, disconnecting socket');
      disconnectSocket();
      socketDispatch({ type: 'SET_SOCKET', payload: null });
      socketDispatch({ type: 'SET_CONNECTED', payload: false });
      socketDispatch({ type: 'SET_RECONNECTING', payload: false });
      socketDispatch({ type: 'SET_RECONNECTION_ATTEMPTS', payload: 0 });
    }
  
    return () => {
      //console.log('SocketProvider: Unmounted');
      disconnectSocket();
    };
  }, [isAuthenticated, accessToken, handleModelConfigUpdate, reduxDispatch]);

  //console.log('SocketProvider: Rendering, isConnected:', state.isConnected);

  return (
    <SocketContext.Provider value={state}>
      {children}
    </SocketContext.Provider>
  );
};